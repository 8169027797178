<template>
  <v-form>
    <v-card color="#F5F5F5" flat>
      <loading :active.sync="loading"></loading>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            ความคิดเห็นจากศิษย์เก่า
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="6" sm="6">
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <DialogOldStudents @getAllStudentComment="getAllStudentComment" />
              <DialogOldStudentsEdit
                :itemOldStudentEdit="itemOldStudentEdit"
                :dialogOldStudentEdit="dialogOldStudentEdit"
                @getAllStudentComment="getAllStudentComment"
                @closDialogOldStudentEdit="closDialogOldStudentEdit"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-3" color="#F5F5F5" flat>
      <v-row align="center" justify="center">
        <v-card class="pa-6 mt-4" width="100%" flat>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-data-table
                :headers="headersBrand"
                :items="items"
                :search="search"
                disable-pagination
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.image_url`]="{ item }">
                  <v-img :src="item.image_url" width="120px"></v-img>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                  <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
                  <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                </template>
                <template
                  v-slot:body="props"
                  v-if="isWindowWidthGreaterThan768"
                >
                  <draggable
                    :list="props.items"
                    tag="tbody"
                    @change="DragItems(props.items)"
                  >
                    <tr v-for="(item, index) in props.items" :key="index">
                      <td v-if="isWindowWidthGreaterThan768">
                        <v-icon small class="page__grab-icon">
                          mdi-arrow-all
                        </v-icon>
                      </td>
                      <td class="text-start">
                        {{ item.index }}
                      </td>
                      <td class="text-start">
                        <v-img
                          :src="item.image_url"
                          width="120px"
                          class="ma-1"
                        ></v-img>
                      </td>
                      <td class="text-start">
                        {{ item.comment_desc_th }}
                      </td>
                      <td class="text-start">
                        {{ item.comment_from_th }}
                      </td>

                      <td class="text-start">
                        <v-row justify="center">
                          <v-icon @click="ViewNews(item)"
                            >mdi-content-copy</v-icon
                          >
                          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
                          <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                        </v-row>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card>
  </v-form>
</template>
<!-- <template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">จัดการคอมเม้นนักศึกษา</span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="#2AB3A3" dark
              >เพิ่มคอมเม้นนักศึกษา</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersBrand"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.image_url`]="{ item }">
              <v-img :src="item.image_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
              <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
              <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template> -->
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
import DialogOldStudents from "../Dialogs/DialogOldStudents1.vue";
import DialogOldStudentsEdit from "../Dialogs/DialogOldStudentsEdit1.vue";

export default {
  components: {
    draggable,
    Loading,
    DialogOldStudents,
    DialogOldStudentsEdit,
  },
  data() {
    return {
      //       comment_desc: "comment_desc"
      // comment_from: "comment_from"
      // comment_position: "comment_position"
      // createdAt: (...)
      // id: (...)
      // index: 1
      // updatedAt: (...)
      comment_desc: "",
      comment_from: "",
      comment_position: "",
      headersBrand: [],
      headersBrand1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูปผู้รีวิว", value: "image_url", align: "start" },
        {
          text: "รีวิว (TH)",
          value: "comment_desc_th",
          align: "start",
          width: "500px",
        },
        {
          text: "ชื่อผู้รีวิว (TH)",
          value: "comment_from_th",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrand2: [
        { text: "ลำดับ", value: "index", align: "start" },
        { text: "รูปผู้รีวิว", value: "image_url", align: "start" },
        {
          text: "รีวิว (TH)",
          value: "comment_desc_th",
          align: "start",
          width: "500px",
        },
        {
          text: "ชื่อผู้รีวิว (TH)",
          value: "comment_from_th",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      search: "",
      index: 0,
      user: "",
      loading: false,
      imgUrl: "",
      itemOldStudentEdit: {},
      dialogOldStudentEdit: false,
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAllStudentComment();
    document.title = "ความคิดเห็นจากศิษย์เก่า - GSCM Management System";
  },

  mounted() {
    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersBrand = this.headersBrand1;
    } else {
      this.headersBrand = this.headersBrand2;
    }
  },

  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("DragItems", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/studentComments/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments?type=2`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items = response1.data.data;
      if (this.items.length) {
        let miniText = "";
        for (let i in this.items) {
          miniText = this.items[i].comment_desc_th.substring(0, 500);
          this.items[i].comment_desc_th = miniText;
        }
      }
      this.loading = false;
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },

    async getAllStudentComment() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments?type=2`,
        auth
      );
      console.log("getAllStudentComment", response.data.data);
      this.items = response.data.data;

      if (this.items.length) {
        let miniText = "";
        for (let i in this.items) {
          miniText = this.items[i].comment_desc_th.substring(0, 500);
          this.items[i].comment_desc_th = miniText;
        }
      }

      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].index = parseInt(i) + 1;
      }
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/studentComments/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllStudentComment();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateStudentComment");
    },

    async goToEdit(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments/${val.id}`,
        auth
      );
      console.log("getOneStudentComment", response.data.data);
      this.itemOldStudentEdit = response.data.data;

      this.dialogOldStudentEdit = true;
    },
    closDialogOldStudentEdit() {
      this.dialogOldStudentEdit = false;
    },
    async ViewNews(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      console.log("duplicateData", duplicateData);
      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/studentComments`,
        duplicateData,
        auth
      );
      console.log("response1", response1);
      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAllStudentComment();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loading = false;
    },
  },
};
</script>
