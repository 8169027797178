<template>
  <div>
    <ManageStudentComment />
  </div>
</template>
<script>
import ManageStudentComment from "@/components/StudentComment/ManageStudentComment";
export default {
  components: {
    ManageStudentComment,
  },
  created() {},
};
</script>
